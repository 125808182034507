import {Chart} from 'chart.js/auto';
window.Chart = Chart;
import {AjaxPromise, AjaxSync} from "../shared/ajax_utilities";
import {displayErrors} from "../shared/common";

/**
 * Get vehicle levels (rep + veh_lev)
 * 
 * Filters
 * Stats
 */
$(function(){

    // load state
    var loaded = false,
        loading = false,
        tbl = 'veh_lev',
        // reference field
        f = 'vehicle_id',
        // state of field depending on add/edit
        fState = 'disabled',
        $tab = app.DOM.form.find('.tab-levels'),
        $graphContainer = $tab.find('#graph-container');

    $tab.on('initial_open', () => {
        app.FORM.setupVehLev();
    });

    // count so its accessible from other js files
    // determines if signoff notes are required
    app.DOM.count = $tab.find('#' + tbl + '_count');

    app.FORM.setupVehLev = function(){

        // do nothing
        if(loaded || loading) {
            return;
        }

        loading = true;

        // change form state
        app.DOM.form.addClass('form-' + app.FORM.action);

        var $btnAdd = $tab.find('#' + tbl + '_btn_add'),
            $btnIsDeleted = $tab.find('h3 .is_deleted'),
            $table = $tab.find('table'),
            $tbody = $table.find('tbody'),
            $trEmpty = $tbody.find('tr.empty'),
            // filters
            $btnFilter = app.DOM.form_filter_btn.filter('#btn_filter_level'),
            $filter = $btnFilter.closest('.filter'),
            $btnFilterClear = $filter.find('#btn_filter_level_clear').hide(),
            $filterInputs = $filter.find('input').not('.original'),
            // graph
            $btnGraph = $tab.find('a.btn-graph'),
            $containerGraph = $tab.find('.graph-container'),
            $canvasGraph = $containerGraph.find('canvas'),
            graphs = [],
            // template
            $trTemplate,
            $trTemplateInputs,
            // recached
            $trs,
            $trsInputs,
            $btnCancel,
            $btnEdit,
            $btnSave,
            $btnDelete,
            $btnView;

        function gogogo(init){

            // apply default date filters
            if( init ) {
                $filterInputs.filter('.date_from').val(moment().subtract(2, 'months').format(formatInputDate));
                $filterInputs.filter('.date_to').val(app.OPTIONS.date.dmy);
            }

            var url = `${app.CACHE.URL_AJAX_TPL}vehicle_levels`,
                data = getData('get', false, init);

            // change tabel state
            $table.addClass('ajax');

            const filter = ( data.date_from_fuel || data.date_to_fuel ) ? true : false;
            AjaxSync({
                url: url,
                method: 'POST',
                data: data
            }, {
                done: (res) => {
                    // show clear button
                    if( filter ) {
                        $btnFilterClear.show();
                    } else {
                        $btnFilterClear.hide();
                    }

                    // change loaded state
                    loaded = true;

                    // update table
                    $tbody.find('tr.loading').hide();

                    // filter only
                    $tbody.find('tr.is_deleted_0,tr.is_deleted_1').not('.template,.loading,.empty').remove();

                    // response info
                    paintTable(res.trim(), init);
                },
                always: (result) => {
                    // reset table state
                    $table.removeClass('ajax');

                    // reset loading state
                    loading = false;

                    // reset filter state
                    $btnFilter.removeClass('ajax');
                }
            });
        }

        function paintTable(html, init) {

            $tbody.append(html);

            // dom
            setupDom(init);
            
            // events
            setupEvents(init, html);

            // update count
            updateCount();
        }

        function filterResetStats(){
            var $stats = $trs.filter('tr.filter-stats-row');

            // remove prev stats
            $tab.find('.filter-stats-moved').remove();

            if($stats.length > 0) {

                // html of stats
                var html = $stats.find('td').html();

                // remove stats from dom
                $stats.remove();

                // put stats html in dom
                $filter.after(html);

                // add class moved so state changed
                $tab.find('.filter-stats').addClass('filter-stats-moved');
            }
        }

        function updateCount(){

            var isDeleted = ($table.hasClass('deleted')) ? '1' : '0',
                count = $tbody.find('tr.is_deleted_' + isDeleted + '[data]').length;

            // update dom count
            app.DOM.count.html(count);

            // remove empty row depending on numbers
            if(count === 0) {
                $trEmpty.show();
                $graphContainer.hide();
            } else {
                $trEmpty.hide();
                $graphContainer.show();
            }
        }

        function checkErrors(err) {
            var msg = 'Please enter the missing field(s):\n\n';

            // get friendly name of input
            $.each(err, function (k, v) {
                msg += v.replace('is_', '').replace('id', '').replace('_', ' ').replace(/(^\w|\s\w)/g, m => m.toUpperCase()) + '\n';
            });

            // alert users
            alert(msg);
        }

        function btnAdd(){
            // do nothing
            if($(this).hasClass('button-grey')) {
                return;
            }

            $(this).addClass('button-grey').removeClass('button-green');

            // make sure not viewing deleted rows
            if($table.hasClass('deleted')) {
                $btnIsDeleted.eq(0).trigger('click');
            }

            // change table state
            $table.addClass('add');

            // enable inputs
            $trTemplateInputs.removeClass('disabled').prop(fState, false);
        }

        /**
         * Ajax data
         */
        function getData(action, $tr, init) {
            var data = {
                'action': action,
                'tbl_ref': app.URI[1],
                'row_id': ($.isNumeric(app.URI[2])) ? app.URI[2] : '0'
            };

            if(init) {
                data.init = init;
            }

            // get filters
            $filterInputs.each(function(){
                data[this.id] = this.value;
            });

            // identifier
            if(action === 'edit' || action === 'delete') {
                data.id = $tr.attr('data');
            }
            
            // submit previously posted defects
            if(app.OPTIONS.mai_ans_defect_post) {
                data.mai_ans_defect = app.OPTIONS.mai_ans_defect_post;
            }

            return data;
        }

        // add only
        async function btnSave(){
            var $tr = $(this).closest('tr'),
                action = ($tr.hasClass('template')) ? 'add' : 'edit', // ajax action
                err = [], // error container
                data = getData(action, $tr);

            var inputs = '.required-' + action;

            if(action === 'add') {
                inputs += ',.required-edit';
            }

            var $inputs = $tr.find(inputs);

            // loop through inputs to see if they have data
            $inputs.each(function(){

                var $td = $(this).closest('td'),
                    k = $td.attr('data');

                // error
                if($(this).hasClass('exclude') === false && !this.value) {
                    err.push(k);
                    $(this).addClass('error');
                }

                // set data if not already set
                // possible with 2 inputs in same <td>  
                if(!data[k]) {

                    // get different input for autocomplete
                    if(k === f) {
                        data[k] = $td.find('input.' + f).val();
                    } else {
                        data[k] = this.value;
                    }
                }
            });

            // check for errors
            if(err.length > 0) {
                checkErrors(err);
                return;
            }

            // change row state
            var $tr = $(this).closest('tr').addClass('ajax');

            var id = ( action === 'add' ) ? '' : '/'+data.id;

            try {
                const res = await AjaxPromise({
                    url: `${app.CACHE.URL_AJAX}${tbl}/${action}${id}`,
                    method: 'POST',
                    data: data
                });

                if(res && res.status === 'success') {
                    // remove previous erro rstate for input
                    $tr.find(':input').removeClass('error');
                    if(action === 'add') {
                        btnSaveSuccessAdd(res, $inputs);
                    } else if(action === 'edit') {
                        btnSaveSuccessEdit($tr, res);
                    }
                } else {
                    displayErrors('There was a problem, please try again later.');
                }
            } finally {
                $tr.removeClass('ajax edit');

                // add only
                if(action === 'add') {
                    resetAdd();
                }
            }
        }

        function btnSaveSuccessEdit($tr, r) {
            // highlight row
            $tr.addClass('highlight');

            // disable inputs
            $tr.find(':input').addClass('disabled').prop(fState, true);

            // remove highlight
            setTimeout(function(){
                $tr.removeClass('highlight');
            }, 1000);
        }

        function btnSaveSuccessAdd(r, $inputs) {
            // disable add state
            resetAdd(true);

            // get html
            var html = '<tr data="' + r.data + '" class="is_deleted_0 highlight" id="' + tbl + '_' + r.data + '" style="display:none">' +
                $trTemplate.html() +
                +'</tr>';

            // add row
            $trTemplate.after(html);

            // cache highlight
            var $row = $table.find('.highlight');

            // remove highlight
            setTimeout(function(){
                $row.removeClass('highlight');
            }, 1000);

            // cleanup row
            $row.find(':input').removeAttr('id');
            $row.fadeIn();

            // loop through each input and trasnfer to new row
            // also add a new name
            $inputs.each(function(){
                var field = $(this).closest('td').attr('data');
                $row.find('td[data="' + field + '"]').find(':input').val(this.value).attr('name', tbl + '[' + r.data + '][' + field + ']');
            });

            // cleanup template
            $trTemplateInputs.val('').trigger('blur');

            // recount
            updateCount();

            // recache
            setupDom();
            setupEvents();
        }

        function btnIsDeleted(){
            // change h3 state
            $(this).closest('h3').toggleClass('deleted bg-red');

            // change table state
            $table.toggleClass('deleted');

            // recount
            updateCount();
        }

        function btnDeleteSuccess($tr, data) {
            // when adding inspection only remove from dom
            if(app.FORM.action === 'add') {
                $tr.remove();
            } else {
                var isDeletedOpposite = (data.is_deleted === '0') ? '1' : '0';

                // change row state
                $tr.removeClass('is_deleted_' + isDeletedOpposite).addClass('is_deleted_' + data.is_deleted);
            }


            // recount
            updateCount();
        }

        function btnEdit(){
            var $tr = $(this).closest('tr').addClass('edit');

            // show fields that can be edited
            $tr.find(':input').removeClass('disabled').prop(fState, false);
        }

        function resetAdd(cancel) {
            $btnAdd.removeClass('button-grey').addClass('button-green');

            if(cancel) {
                $trTemplate.find('.btn-cancel').trigger('click');
            }
        }

        function btnCancel(){
            // disable row edit state
            var $tr = $(this).closest('tr').removeClass('edit');

            // cancel template row
            if($tr.hasClass('template')) {
                resetAdd();

                // disable table add state
                $table.removeClass('add');
            }

            // change input state
            $tr.find(':input').addClass('disabled').prop(fState, true);
        }

        async function btnDelete(){
            // do nothing
            if(!confirm("Are you sure you want to " + $(this).attr('title') + '?')) {
                return;
            }

            // data
            var $tr = $(this).closest('tr'),
                data = getData('delete', $tr);

            // add delete state
            data.is_deleted = $(this).attr('data');

            try {
                const res = await AjaxPromise({
                    url: app.CACHE.URL_AJAX + tbl+'/delete/'+data.id,
                    method: 'POST',
                    data: data
                });

                if(res && res.status === 'success') {
                    btnDeleteSuccess($tr, data);
                } else {
                    displayErrors('There was a problem, please try again later.');
                }
            } finally {
                $tr.removeClass('ajax');
            }
        }

        // redirect user
        function btnView(){
            // parent row
            var $tr = $(this).closest('tr').addClass('ajax');

            // redirect
            window.location = $tr.find('td').eq(0).find('a').attr('href');
        }

        function setupEventsInit(){

            // DELETE
            $btnAdd.on('click', btnAdd);

            // TOGGLE DELETED
            $btnIsDeleted.on('click', btnIsDeleted);

            // disable submit
            $trTemplateInputs.on('keydown', function (e) {
                if(e.keyCode === 13) {
                    e.preventDefault();
                }
            });

            // FILTER
            $btnFilter.unbind().on('click', btnFilter);
        }

        function setupGraph(init){
            // BUTTONS
            if( init ) {
                $btnGraph.on('click', function(){

                    // do nothing
                    if($(this).hasClass('button-green')) {
                        return;
                    }
    
                    // remove state
                    $btnGraph.removeClass('button-green').addClass('button-grey');
    
                    // change state
                    $(this).addClass('button-green').removeClass('button-grey');
    
                    // remove state
                    $containerGraph.removeClass('open');
    
                    // change state
                    $containerGraph.eq($(this).index()).addClass('open');
    
                }).eq(0).trigger('click');
            } else {

                // clear previous graphs
                $.each(graphs, function(k,v){
                    v.destroy();
                });
                
                graphs = [];
            }

            // CHARTS
            var graphData = {},
                labels = [],
                tdClass = ['td[data="date_start"]', 'td.date-db'];

            $btnGraph.each(function(){
                var k = $(this).attr('data');
                tdClass.push('td[data-key="' + k + '"]');
                graphData[k] = [];
            });

            // cache for debug
            var $trsData = $trs.not('.loading,.empty,.template,.is_deleted_1,.th').find(tdClass.join(','));

            // build data loop through <td>
            $trsData.each(function(){

                var k = $(this).attr('data-key');

                if( $(this).attr('data') === 'date_start' || $(this).hasClass('date-db') ) {

                    labels.push($(this).find('span').text());

                } else {

                    // records key
                    var input = ($(this).attr('data')) ? true : false,
                        val = 0;
    
                    if(input) {
                        val = $(this).find('input').val();
                    } else {
                        val = $(this).text().replace(',', '');
                    }
    
                    // add data
                    val = parseInt(val);

                    if( !isNaN(val) ) {
                        graphData[k].push(val);
                    }
                }
            });

            // use index to setup graphs and data
            var index = 0;
            // reverse labels
            labels.reverse();

            // Loop through chart data
            $.each(graphData, function(k,v){

                if( v.length === 0 ) {

                    $btnGraph.eq(index).hide();

                    index++;
                    return;
                }

                // show button
                $btnGraph.eq(index).show();

                const data = {
                    labels: labels,
                    datasets: [{
                      label: $btnGraph.eq(index).text(),
                      data: v.reverse(),
                      fill: false,
                      borderColor: 'rgb(75, 192, 192)',
                      tension: 0.1
                    }]
                  };

                const config = {
                    type: 'line',
                    data: data,
                    options: {
                        maintainAspectRatio: false
                    }
                };

                graphs.push(new Chart(
                    $canvasGraph.eq(index),
                    config
                ));

                // increment
                index++;
            });

            $btnGraph.filter(':visible').first().trigger('click');
        }

        function btnFilter(){

            // do nothing
            if( $(this).hasClass('ajax') ) {
                return;
            }

            // change button state
            $(this).addClass('ajax');

            // show loading
            $tbody.find('tr').not('tr.template').hide().filter('tr.loading').show();

            // change table state
            $table.removeClass('add');
            // change button add state
            $btnAdd.removeClass('button-grey').addClass('button-green');

            // start again with filters
            gogogo(false);
        }

        function setupDom(init) {
            $trs = $table.find('tr');
            $trsInputs = $trs.find(':input');
            $btnCancel = $trs.find('.btn-cancel');
            $btnDelete = $trs.find('.btn-delete,.btn-enable');
            $btnEdit = $trs.find('.btn-edit');
            $btnSave = $trs.find('.btn-save');
            $btnView = $trs.find('.btn-view');

            if(init) {
                $trTemplate = $trs.filter('.template');
                $trTemplateInputs = $trTemplate.find(':input');
            }
        }

        function setupDatepickers(){
            // remove any existing events for datepickers
            $trsInputs.filter('.datetimepicker').removeAttr('id').removeClass('hasDatepicker').unbind();

            // setup start
            $trsInputs.filter('.datetimepicker').datetimepicker({
                changeMonth: true,
                changeYear: true,
                maxDate: 0,
                onSelect: function (val) {
                    // update <span> with formatted datetime
                    $(this).next().text(moment(val, formatInputDatetime).format('Do MMM YYYY, HH:mm'));
                }
            });
        }

        function setupEvents(init) {

            if(init) {
                setupEventsInit();
            }
            
            // disable inputs
            $trs.not('.template').find(':input').addClass('disabled').prop(fState, true);
            
            // datepickers
            setupDatepickers();
            
            // save
            $btnSave.unbind().on('click', btnSave);
            
            // cancel
            $btnCancel.unbind().on('click', btnCancel);
            
            // edit
            $btnEdit.unbind().on('click', btnEdit);
            
            // delete
            $btnDelete.unbind().on('click', btnDelete);
            
            // view
            $btnView.unbind().on('click', btnView);
            
            // clear any prev stats and show new ones
            filterResetStats();

            // graphs
            if( $btnGraph.length > 0 ) {
                setupGraph(init);
            }
        }

        gogogo(true);
    };
});